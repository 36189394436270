/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 87번째 로그에서는 GitHub의 master 브랜치, Ruby 분석기 rubocop 이름 논란, 맥북 크롬에서 생기는 파란 줄 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "인트로: IoT 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/1768796"
  }, "스탠다드아웃 | 오디오 천국 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://m.ddaily.co.kr/m/m_article/?no=190263"
  }, "샤오미 홈카메라 보안 구멍··· 다른 집 화면이 보인다?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Hype_cycle"
  }, "Hype cycle - Wikipedia")), "\n"), "\n", React.createElement(_components.h3, null, "GitHub master 브랜치 / Ruby 분석기 rubocop 이름 논란"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Hype_cycle"
  }, "Hype cycle - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rubocop-hq/rubocop/issues/8091"
  }, "Is it time to change the name? · Issue #8091 · rubocop-hq/rubocop"), "]"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://english.stackexchange.com/questions/51088/alternative-term-to-blacklist-and-whitelist"
  }, "offensive language - Alternative term to \"Blacklist\" and \"Whitelist\" - English Language & Usage Stack Exchange")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/bbatsov"
  }, "bbatsov (Bozhidar Batsov)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/bbatsov/status/1269490401054019584"
  }, "Bozhidar Batsov (Bug) on Twitter: \"It's really painful to wake up to hostile comments like this one in my twitter timeline. ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/9/17/20870050/richard-stallman-resigns-mit-free-software-foundation-epstein"
  }, "Richard Stallman resigns from MIT over Epstein comments - The Verge")), "\n"), "\n", React.createElement(_components.h3, null, "맥북, 크롬 파란 줄 발생"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/news/15073885"
  }, "macOS 크롬에서 '파란색 줄' 그래픽 문제 나타나 : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lifewire.com/hardware-acceleration-in-chrome-4125122"
  }, "How to Turn Hardware Acceleration On and Off in Chrome")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/HT204455"
  }, "Mac에서 펌웨어 암호를 설정하는 방법 - Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://muchtrans.com/translations/software-disenchantment.ko.html"
  }, "소프트웨어 환멸감")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/nacyo_t/status/1270846547723382784"
  }, "Daegwon Nacyot Kim on Twitter: \"Activity Monitor 열 때마다, Slack, Notion, Tandem이 맥북을 망가뜨리고 있다는 기분에서 벗어날 수 없다... 기분이 아님.\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://code.visualstudio.com/"
  }, "Visual Studio Code - Code Editing. Redefined")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.electronjs.org/"
  }, "Electron | Build cross-platform desktop apps with JavaScript, HTML, and CSS.")), "\n"), "\n", React.createElement(_components.h3, null, "단신"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.biz.chosun.com/svc/article.html?contid=2020061602045&Dep0=com.google.android.googlequicksearchbox&utm_source=com.google.android.googlequicksearchbox&utm_medium=unknown&utm_campaign=biz&docRefURL=android-app%3A%2F%2Fcom.google.android.googlequicksearchbox%2Fhttps%2Fwww.google.com"
  }, "아마존, MS 본사 옆에 새 사무실… 클라우드 신경전 가열 - Chosunbiz > 테크")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/388544"
  }, "중국 알리바바, 클라우드 인력 5000명 충원한다 | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/news/15067316"
  }, "기이한 인터넷 '닷' 오류가 공짜 유튜브 시청과 페이월 통과를 가능케 해주다 : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/Journalism/comments/892enx/wsj_subscription_policy_makes_it_easy_to/"
  }, "WSJ Subscription policy makes it easy to subscribe (online), but hard to unsubscribe (via phone). : Journalism")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hkbnews.com/article/view/9458"
  }, "브레이브, 바이낸스 '레퍼럴 주소' 자동완성 기능 논란 - 한국블록체인뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.thebchain.co.kr/news/articleView.html?idxno=7433"
  }, "왓챠 주도 블록체인 프로젝트 접는다...\"규제 불확실성‧사업 전망 부족\" - 더비체인")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kasa.co.kr/?utm_source=kakao&utm_medium=network&utm_campaign=cpc&utm_content=interests&utm_term=buildingmarket#/"
  }, "처음 만나는 건물재테크, 카사 - Kasa")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mutagen.io/"
  }, "Mutagen | Cloud-based development using your local tools")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--docker-desktop-for-mac-edge-channel-with-mutagen-based-caching"
  }, "Docker for Mac Edge 채널에 Mutagen 기반 캐싱 기능 추가 | 44BITS")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
